import { useEffect, useRef, useState } from 'react';
import { SoundType } from '../models/soundType';
import { fileApi, themeApi } from '../generated/clients';
import { SoundMap, ThemeDTO } from '../generated';
import { uploadFile } from '../utils/api-helpers';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import { Box, Button, Container, FormControl, Grid2, InputLabel, MenuItem, Select, Stack, Typography } from '@mui/material';

const enumToArray = () => Object.keys(SoundType).filter((key) => isNaN(Number(key)));

interface SoundMapProps {
  soundIndex: number;
  soundMap?: SoundMap;
  fileSelectionComplete: (soundFile: File) => void;
}

const SoundSelector = (props: SoundMapProps) => {
  const [soundFile, setSoundFile] = useState<File | null>();
  const [soundFileUri, setSoundFileUri] = useState<string>('');
  const fileInput = useRef<any>(null);

  let r = new FileReader();

  useEffect(() => {
    const load = async () => {
      if (props.soundMap?.id !== undefined) {
        const response = (await fileApi.fileGetFileByIdRaw({ id: props.soundMap.soundId })).raw;
        if (response) {
          r.readAsDataURL(await response.blob());
        }
      }
    };
    load();
  }, [props.soundMap]);

  r.onloadend = (e) => {
    const result = r.result?.toString();
    if (result) {
      setSoundFileUri(result);
    }
  };

  return (
    <div>
      <Typography variant="h6" sx={{ textTransform: 'capitalize' }}>
        {SoundType[props.soundIndex]}
      </Typography>
      <Box>
        <Stack>
          <Button sx={{ mb: 1 }} variant="outlined" onClick={() => fileInput?.current?.click()}>
            Open
          </Button>
          {soundFile && (
            <Button
              sx={{ mb: 1 }}
              variant="outlined"
              onClick={() => {
                props.fileSelectionComplete(soundFile);
                setSoundFile(null);
              }}
            >
              Save
            </Button>
          )}
        </Stack>
        <AudioPlayer src={soundFileUri} />
      </Box>
      <input
        hidden
        ref={fileInput}
        className="exercise-image-file-input"
        type="file"
        onChange={(e) => {
          let f = e.target.files ? e.target.files[0] : '';
          if (e.target.files) {
            setSoundFile(e.target.files[0]);
            setSoundFileUri(URL.createObjectURL(e.target.files[0]));
          }
        }}
      />
    </div>
  );
};

export function Themes() {
  const [themeList, setThemeList] = useState<Array<string>>();
  const [themeName, setThemeName] = useState<string>('');
  const [renderTheme, setRenderTheme] = useState<ThemeDTO>();

  useEffect(() => {
    async function initialize() {
      if (!themeList) {
        const l = await themeApi.themeListNames();
        setThemeList(l);
      }
      if (themeName !== '') {
        console.log('Getting theme from server');
        const t = await themeApi.themeGetByName({ name: themeName });
        setRenderTheme(t);
        setThemeName('');
      }
    }
    initialize();
  }, [themeList, themeName]);

  return (
    <Container>
      <Box>
        <FormControl sx={{ mt: 1 }}>
          <InputLabel id="theme-label">Theme</InputLabel>
          <Select labelId="theme-label" label="Theme" sx={{ minWidth: 100 }} onChange={(p) => p.target.value as string}>
            {themeList?.map((t) => (
              <MenuItem onClick={() => setThemeName(t)} selected={themeName === t} value={t}>
                {t}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      {renderTheme ? <ThemeBlock renderTheme={renderTheme} setRenderTheme={(t) => setRenderTheme(t)} /> : null}
    </Container>
  );
}

function ThemeBlock(props: { renderTheme: ThemeDTO; setRenderTheme: (t: ThemeDTO) => void }) {
  return (
    <>
      <Box sx={{ m: 1 }}>
        <Typography variant="h4">Sounds</Typography>
        <Grid2 container>
          {enumToArray().map((s, i) => (
            <Grid2 size={{ md: 3, xs: 6 }} sx={{ m: 1 }}>
              <SoundSelector
                key={s}
                soundIndex={i}
                soundMap={props.renderTheme.soundMap[i]}
                fileSelectionComplete={async (file) => {
                  const fileId: string = await uploadFile(file);
                  let soundMap: SoundMap = { id: i, soundId: fileId };
                  const soundArray = [...props.renderTheme.soundMap];
                  soundArray[i] = soundMap;
                  props.setRenderTheme({ ...props.renderTheme, soundMap: soundArray });
                  themeApi.themeUpdate({ themeDTO: { ...props.renderTheme, soundMap: soundArray } });
                }}
              />
            </Grid2>
          ))}
        </Grid2>
      </Box>
    </>
  );
}

function ColorSelector(props: { title: string; color: string; setColor: (s: string) => void }) {
  return (
    <div style={{ width: '200px', textAlign: 'right', color: 'white' }}>
      {props.title}: <input type="color" id={props.color} value={props.color} onChange={(e) => props.setColor(e.target.value)}></input>
    </div>
  );
}

<>
  {/* <div>
  <ColorSelector
    title="Background"
    color={props.renderTheme.css.background}
    setColor={(color) => props.setRenderTheme({ ...props.renderTheme, css: { ...props.renderTheme.css, background: color } })}
  />
  <ColorSelector
    title="ActiveTintColor"
    color={props.renderTheme.css.activeTintColor}
    setColor={(color) => props.setRenderTheme({ ...props.renderTheme, css: { ...props.renderTheme.css, activeTintColor: color } })}
  />
  <ColorSelector
    title="HeaderColor"
    color={props.renderTheme.css.headerColor}
    setColor={(color) => props.setRenderTheme({ ...props.renderTheme, css: { ...props.renderTheme.css, headerColor: color } })}
  />
  <ColorSelector
    title="HeaderTintColor"
    color={props.renderTheme.css.headerTintColor}
    setColor={(color) => props.setRenderTheme({ ...props.renderTheme, css: { ...props.renderTheme.css, headerTintColor: color } })}
  />
  <ColorSelector
    title="TabIconSelected"
    color={props.renderTheme.css.tabIconSelected}
    setColor={(color) => props.setRenderTheme({ ...props.renderTheme!, css: { ...props.renderTheme!.css, tabIconSelected: color } })}
  />
  <ColorSelector
    title="TabIconDefault"
    color={props.renderTheme.css.tabIconDefault}
    setColor={(color) => props.setRenderTheme({ ...props.renderTheme, css: { ...props.renderTheme.css, tabIconDefault: color } })}
  />
  <ColorSelector
    title="Tint"
    color={props.renderTheme.css.tint}
    setColor={(color) => props.setRenderTheme({ ...props.renderTheme, css: { ...props.renderTheme.css, tint: color } })}
  />
  <ColorSelector
    title="Text"
    color={props.renderTheme.css.text}
    setColor={(color) => props.setRenderTheme({ ...props.renderTheme, css: { ...props.renderTheme.css, text: color } })}
  />
  <ColorSelector
    title="LargeInfoTextColor"
    color={props.renderTheme.css.largeInfoTextColor}
    setColor={(color) => props.setRenderTheme({ ...props.renderTheme, css: { ...props.renderTheme.css, largeInfoTextColor: color } })}
  />
</div> */}
</>;
