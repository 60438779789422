/* tslint:disable */
/* eslint-disable */
/**
 * Sports App Training Plans
 * API for the Sports App Training Plans
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SetDTO
 */
export interface SetDTO {
    /**
     * 
     * @type {string}
     * @memberof SetDTO
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof SetDTO
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof SetDTO
     */
    imageId?: string;
    /**
     * 
     * @type {string}
     * @memberof SetDTO
     */
    creatorId: string;
    /**
     * 
     * @type {string}
     * @memberof SetDTO
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof SetDTO
     */
    creatorName: string;
    /**
     * 
     * @type {string}
     * @memberof SetDTO
     */
    modificationTimestamp: string;
}

/**
 * Check if a given object implements the SetDTO interface.
 */
export function instanceOfSetDTO(value: object): value is SetDTO {
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('creatorId' in value) || value['creatorId'] === undefined) return false;
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('creatorName' in value) || value['creatorName'] === undefined) return false;
    if (!('modificationTimestamp' in value) || value['modificationTimestamp'] === undefined) return false;
    return true;
}

export function SetDTOFromJSON(json: any): SetDTO {
    return SetDTOFromJSONTyped(json, false);
}

export function SetDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): SetDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'description': json['description'] == null ? undefined : json['description'],
        'imageId': json['imageId'] == null ? undefined : json['imageId'],
        'creatorId': json['creatorId'],
        'id': json['id'],
        'creatorName': json['creatorName'],
        'modificationTimestamp': json['modificationTimestamp'],
    };
}

export function SetDTOToJSON(json: any): SetDTO {
    return SetDTOToJSONTyped(json, false);
}

export function SetDTOToJSONTyped(value?: SetDTO | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'name': value['name'],
        'description': value['description'],
        'imageId': value['imageId'],
        'creatorId': value['creatorId'],
        'id': value['id'],
        'creatorName': value['creatorName'],
        'modificationTimestamp': value['modificationTimestamp'],
    };
}

