import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { Box, Button, Stack, TextField, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { userApi } from '../generated/clients';

export const Register = (props: any) => {
  const { enqueueSnackbar } = useSnackbar();
  const [username, setUsername] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [repeatPassword, setRepeatPassword] = useState<string>();
  const navigate = useNavigate();
  const [validated, setValidated] = useState(false);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [token, setToken] = useState('');

  useEffect(() => {
    if (!executeRecaptcha) {
      return;
    }
    const handleReCaptchaVerify = async () => {
      const token = await executeRecaptcha('register');
      setToken(token);
    };
    handleReCaptchaVerify();
  }, [executeRecaptcha]);

  const submit = async () => {
    try {
      await userApi.userRegister({ registerUserWebAppDTO: { email: email, username: username, password, captchaToken: token } });
      enqueueSnackbar('Registration Complete. Please confirm your email address with the link that was sent to your mailbox and log in.', { variant: 'success' });
      navigate('/login');
    } catch (e) {
      console.error(e);
      if (e instanceof Error) {
        enqueueSnackbar(`Registration failed with error. ${e.message}`, { variant: 'error' });
      }
    }
  };

  return (
    <Box>
      <Stack sx={{ my: 3, alignItems: 'center' }} spacing={3} direction={'column'}>
        <Typography variant="h4" sx={{ color: '#fefae0' }}>
          Create a new Account
        </Typography>
        <TextField
          sx={{ minWidth: 250 }}
          required
          error={email.length === 0}
          helperText={email.length === 0 ? 'Please provide a valid email address.' : undefined}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
          label="Email Address"
        />
        <TextField
          sx={{ minWidth: 250 }}
          required
          error={username.length === 0}
          helperText={username.length === 0 ? 'Please provide a username.' : undefined}
          onChange={(e) => {
            setUsername(e.target.value);
          }}
          label="Username"
        />
        <TextField
          required
          sx={{ minWidth: 250 }}
          type="password"
          label="Password"
          error={password.length === 0}
          helperText={password.length === 0 ? 'Please type a password.' : undefined}
          onChange={(e) => {
            setPassword(e.target.value);
          }}
        />
        <TextField
          required
          sx={{ minWidth: 250 }}
          type="password"
          label="Repeat Password"
          error={password.length === 0}
          helperText={password.length === 0 ? 'Please repeat your password.' : undefined}
          onChange={(e) => {
            setRepeatPassword(e.target.value);
          }}
        />
        <Button
          style={{ color: 'white' }}
          type="submit"
          onClick={async () => {
            await submit();
          }}
        >
          Create New Account
        </Button>
      </Stack>
    </Box>
  );
};
