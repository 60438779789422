/* tslint:disable */
/* eslint-disable */
/**
 * Sports App Training Plans
 * API for the Sports App Training Plans
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RegisterUserWebAppDTO
 */
export interface RegisterUserWebAppDTO {
    /**
     * 
     * @type {string}
     * @memberof RegisterUserWebAppDTO
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterUserWebAppDTO
     */
    username: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterUserWebAppDTO
     */
    password: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterUserWebAppDTO
     */
    captchaToken: string;
}

/**
 * Check if a given object implements the RegisterUserWebAppDTO interface.
 */
export function instanceOfRegisterUserWebAppDTO(value: object): value is RegisterUserWebAppDTO {
    if (!('email' in value) || value['email'] === undefined) return false;
    if (!('username' in value) || value['username'] === undefined) return false;
    if (!('password' in value) || value['password'] === undefined) return false;
    if (!('captchaToken' in value) || value['captchaToken'] === undefined) return false;
    return true;
}

export function RegisterUserWebAppDTOFromJSON(json: any): RegisterUserWebAppDTO {
    return RegisterUserWebAppDTOFromJSONTyped(json, false);
}

export function RegisterUserWebAppDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): RegisterUserWebAppDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'email': json['email'],
        'username': json['username'],
        'password': json['password'],
        'captchaToken': json['captchaToken'],
    };
}

export function RegisterUserWebAppDTOToJSON(json: any): RegisterUserWebAppDTO {
    return RegisterUserWebAppDTOToJSONTyped(json, false);
}

export function RegisterUserWebAppDTOToJSONTyped(value?: RegisterUserWebAppDTO | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'email': value['email'],
        'username': value['username'],
        'password': value['password'],
        'captchaToken': value['captchaToken'],
    };
}

