import { useContext, useEffect, useState } from 'react';
import { ExerciseSmall } from './exercises/ExerciseSmall';
import { filter, FilterProps } from '../utils/exercise-filter';
import SearchIcon from '@mui/icons-material/Search';
import { Box, Drawer, FormControl, InputAdornment, InputLabel, NativeSelect, Paper, Stack, TextField } from '@mui/material';
import { AppContext } from '../App';
import { useSnackbar } from 'notistack';
import { useExercises } from '../hooks/exercises.hook';
import { ExerciseDTO, SetDTO } from '../generated';
import { exerciseApi } from '../generated/clients';
import { getLocalSetList } from '../utils/api-helpers';

type AddExercisesDrawerProps = {
  set?: SetDTO;
  show: boolean;
  setShow: (show: boolean) => void;
  exerciseList?: Array<ExerciseDTO>;
  exerciseAdded: (exercise: ExerciseDTO) => void;
};

export const AddExercisesDrawer = (props: AddExercisesDrawerProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const { exerciseAdded } = props;
  const context = useContext(AppContext);
  const [set, setSet] = useState<SetDTO>();
  const [exercises, error, refresh] = useExercises(set?.id);
  const [setList, setSetList] = useState<Array<SetDTO>>();
  const [filterProps, setFilterProps] = useState<FilterProps>();
  const [filteredExercises, setFilteredExercises] = useState<Array<ExerciseDTO>>();

  const exerciseClicked = async (e: ExerciseDTO) => {
    try {
      const newExercise = await exerciseApi.exerciseCopy({ copyExerciseDTO: { exerciseId: e.id!, targetSetId: props.set?.id || '' } });
      exerciseAdded(newExercise);
      enqueueSnackbar(`${newExercise.name} added to Set!`, { variant: 'success' });
    } catch (e) {
      if (e instanceof Error) {
        enqueueSnackbar(JSON.parse(e.message).message, { variant: 'error' });
      }
    }
  };
  //TODO: Use Hook
  useEffect(() => {
    async function initialize() {
      if (!setList) {
        const localSetList = await getLocalSetList();
        setSetList(localSetList);
        setSet(localSetList[0]);
      }
    }
    initialize();
  }, [setList]);

  useEffect(() => {
    if (error) enqueueSnackbar(`Failed to load Exercises ${error}`, { variant: 'error' });
  }, [error]);

  useEffect(() => {
    if (filterProps && exercises) setFilteredExercises(filter(exercises, filterProps));
    else setFilteredExercises(exercises);
  }, [filterProps, exercises]);

  return (
    <>
      <Drawer anchor="right" open={props.show} onClose={() => props.setShow(false)}>
        <Box>
          <Paper elevation={30} sx={{ position: 'sticky', top: 0, pb: 3 }}>
            <Stack direction={'row'} style={{ justifyItems: 'center', justifyContent: 'center', alignItems: 'center' }}>
              <FormControl sx={{ mx: 2, mb: 2, mt: 1 }} fullWidth>
                <InputLabel variant="standard" htmlFor="uncontrolled-native">
                  Zone
                </InputLabel>
                <NativeSelect
                  id="input-group-dropdown-1"
                  value={filterProps?.bodyZoneId !== '' && filterProps?.bodyZoneId ? filterProps?.bodyZoneId : 'any'}
                  onChange={(e) => {
                    if (e.target.value !== 'any') setFilterProps({ ...filterProps, bodyZoneId: e.target.value as any });
                    else setFilterProps({ ...filterProps, bodyZoneId: '' });
                  }}
                >
                  <option key={'any'} value={'any'}>
                    Any
                  </option>
                  {context.bodyZoneList?.map((bz) => (
                    <option key={bz.id} value={bz.id}>
                      {bz.name}
                    </option>
                  ))}
                </NativeSelect>
              </FormControl>
            </Stack>
            <Stack direction={'row'} style={{ marginTop: 4, justifyContent: 'center', alignContent: 'center', justifyItems: 'center', alignItems: 'center' }}>
              <TextField
                label="Name"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => {
                  setFilterProps({ ...filterProps, name: e.target.value });
                }}
              />
            </Stack>
            <Stack direction={'row'} style={{ marginTop: 10, justifyItems: 'center', justifyContent: 'center', alignItems: 'center' }}>
              <FormControl>
                <InputLabel variant="standard" htmlFor="uncontrolled-native">
                  from Set
                </InputLabel>
                <NativeSelect
                  value={set?.name}
                  style={{ zIndex: 1 }}
                  id="input-group-dropdown-1"
                  onChange={(e) => {
                    setSet(setList?.find((s) => s.name === e.target.value));
                  }}
                >
                  {setList?.map((s) => (
                    <option value={s.name} onClick={() => setSet(s)}>
                      {s.name}
                    </option>
                  ))}
                </NativeSelect>
              </FormControl>
            </Stack>
          </Paper>
          <Box sx={{ marginTop: 2 }} width={250}>
            {filteredExercises?.map((e) => (
              <ExerciseSmall onClick={() => exerciseClicked(e)} key={e.id} exercise={e} />
            ))}
          </Box>
        </Box>
      </Drawer>
    </>
  );
};
