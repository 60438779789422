import { FormControlLabel, Grid, Grid2, Switch, TextField } from '@mui/material';
import Fieldset from '../Fieldset';
import { TemplateConfig } from '../../generated';

interface ExerciseProps {
  exerciseConfig: TemplateConfig;
  updateExerciseConfig: (exerciseConfig: TemplateConfig) => void;
}

export const ExerciseConfigComponent = (props: ExerciseProps) => {
  return (
    <Grid2 container>
      <Grid2 size={{ sm: 4, md: 3 }}>
        <FormControlLabel
          control={
            <Switch
              checked={props.exerciseConfig.useTimer || false}
              onChange={(e) => {
                props.updateExerciseConfig({ ...props.exerciseConfig, useTimer: !props.exerciseConfig.useTimer });
              }}
              name="useTimer"
            />
          }
          label="Timed"
        />
      </Grid2>
      <Grid2 size={{ sm: 6, md: 4 }}>
        <TextField
          label={'Weight'}
          multiline
          sx={{ m: 1 }}
          maxRows={5}
          value={props.exerciseConfig.weight}
          onChange={(e) => {
            props.updateExerciseConfig({ ...props.exerciseConfig, weight: !isNaN(Number(e.target.value)) ? Number(e.target.value) : 0 });
          }}
        />
      </Grid2>
      <Grid2 size={{ sm: 4, md: 4 }}>
        <TextField
          label={'Reps'}
          multiline
          sx={{ m: 1 }}
          maxRows={5}
          value={props.exerciseConfig.repetitions}
          onChange={(e) => {
            props.updateExerciseConfig({ ...props.exerciseConfig, repetitions: !isNaN(Number(e.target.value)) ? Number(e.target.value) : 0 });
          }}
        />
      </Grid2>
      {props.exerciseConfig.useTimer && (
        <Grid2 size={{ sm: 12, md: 12 }}>
          <Fieldset title="Timer">
            <TextField
              label={'Time'}
              multiline
              sx={{ m: 1 }}
              maxRows={5}
              value={props.exerciseConfig.time}
              onChange={(e) => {
                props.updateExerciseConfig({ ...props.exerciseConfig, time: !isNaN(Number(e.target.value)) ? Number(e.target.value) : 0 });
              }}
            />

            <TextField
              label={'Init'}
              multiline
              sx={{ m: 1 }}
              maxRows={5}
              value={props.exerciseConfig.initTime}
              onChange={(e) => {
                props.updateExerciseConfig({ ...props.exerciseConfig, initTime: !isNaN(Number(e.target.value)) ? Number(e.target.value) : 0 });
              }}
            />

            <TextField
              label={'Break'}
              multiline
              sx={{ m: 1 }}
              maxRows={5}
              value={props.exerciseConfig.breakTime}
              onChange={(e) => {
                props.updateExerciseConfig({ ...props.exerciseConfig, breakTime: !isNaN(Number(e.target.value)) ? Number(e.target.value) : 0 });
              }}
            />
          </Fieldset>
        </Grid2>
      )}
    </Grid2>
  );
};
