import { useEffect, useRef, useState } from 'react';
import ReactCrop, { PixelCrop, Crop } from 'react-image-crop';
import { ImageFromFile } from '@/components/images/ImageFromFile';
import { useDebounceEffect } from '../../utils/debounce';
import { imgPreview } from '../../utils/imageRendering';

import { ExerciseConfigComponent } from '../exercises/ExerciseConfigComponent';
import { ErrorComponent } from '../ErrorComponent';
import { validateExercise } from '../../validation/exercise';
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Stack,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { TagEditModal } from './TagEditModal';
import { Close, Photo } from '@mui/icons-material';
import { ImageFromServer } from '@/components/images/ImageFromServer';
import { TagConfiguration } from '../muscle-tags/TagConfiguration';
import { SimpleTagConfiguration } from '../muscle-tags/SimpleTagConfiguration';
import { modalStyle } from './modalStyle';
import { ExternalLinksComponent } from '../ExternalLinksComponent';
import { ExerciseDTO, SetDTO } from '../../generated';
import { exerciseApi } from '../../generated/clients';
import { uploadFile } from '../../utils/api-helpers';
import { AppContext } from '../../App';
import EquipmentAutocomplete from '../equipment/EquipmentAutocomplete';

export interface EditExerciseModalProps {
  set: SetDTO | undefined;
  exercise: ExerciseDTO;
  setExercise: (exercise?: ExerciseDTO) => void;
  exerciseUpdated: (exercise: ExerciseDTO) => void;
  showTextScanModal: () => void;
  textScanModalResult: string;
}

export const EditExerciseModal = (props: EditExerciseModalProps) => {
  const [validationErrors, setValidationErrors] = useState<Array<string>>([]);
  const [imageFile, setImageFile] = useState<File | null>();
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
  const [simpleTagConfiguration, setSimpleTagSelection] = useState<boolean>(true);
  const [crop, setCrop] = useState<Crop>();
  const imgRef = useRef<HTMLImageElement>(null);
  const [showTagEditModal, setShowTagEditModal] = useState<boolean>(false);

  useEffect(() => {
    if (props.exercise && props.textScanModalResult) props.setExercise({ ...props.exercise, description: props.textScanModalResult });
  }, [props.textScanModalResult]);

  useDebounceEffect(
    async () => {
      if (completedCrop?.width && completedCrop?.height && imgRef.current) {
        // We use canvasPreview as it's much faster than imgPreview.
        imgPreview(imgRef.current, completedCrop);
      }
    },
    100,
    [completedCrop],
  );

  return (
    <Modal
      open={props.exercise !== undefined}
      onClose={(event, reason) => {
        // if (reason && reason === 'backdropClick') return;
        props.setExercise(undefined);
      }}
    >
      <Box sx={{ ...modalStyle }}>
        <TagEditModal
          selectedMuscleIntensities={props.exercise.muscleIntensities}
          setSelectedMuscleIntensities={(mi) => props.setExercise({ ...props.exercise, muscleIntensities: mi })}
          setShow={setShowTagEditModal}
          show={showTagEditModal}
        ></TagEditModal>
        <Box sx={{ my: 1 }}>
          <Stack direction={'row'}>
            <Typography>Edit Exercise</Typography>
            <Box sx={{ display: 'flex', flexGrow: 1, justifyContent: 'flex-end' }}>
              <Close onClick={() => props.setExercise(undefined)} />
            </Box>
          </Stack>
        </Box>
        <Stack>
          <ErrorComponent errors={validationErrors} />

          <TextField
            value={props.exercise?.name}
            placeholder="Title"
            label="Title"
            aria-label="Title"
            aria-describedby="basic-addon1"
            sx={{ my: 1 }}
            onChange={(e) => {
              if (props.exercise) props.setExercise({ ...props.exercise, name: e.target.value });
            }}
          />
          <TextField
            label={'Description'}
            multiline
            sx={{ my: 1 }}
            maxRows={5}
            defaultValue={props.exercise.description}
            value={props.exercise?.description}
            onChange={(e) => {
              if (props.exercise) props.setExercise({ ...props.exercise, description: e.target.value });
            }}
          />
          <Button sx={{ my: 1 }} onClick={() => props.showTextScanModal()}>
            Scan Text
          </Button>
          <input
            style={{ display: 'none' }}
            onChange={(e) => setImageFile((e.target as any).files ? (e.target as any).files[0] : null)}
            accept="image/*"
            id="icon-button-file"
            type="file"
          />
          {imageFile ? (
            <>
              <ReactCrop crop={crop} onComplete={(c) => setCompletedCrop(c)} onChange={(c) => setCrop(c)}>
                <ImageFromFile ref={imgRef} thumbnail={true} style={{ maxWidth: 300, maxHeight: 300 }} image={imageFile}></ImageFromFile>
              </ReactCrop>
              <Button
                onClick={async () => {
                  if (completedCrop && imgRef.current) {
                    const blob = await imgPreview(imgRef.current, completedCrop);
                    const imageFile = new File([blob], 'image.jpeg', {
                      type: blob.type,
                    });
                    setImageFile(imageFile);
                  }
                }}
              >
                Crop
              </Button>
            </>
          ) : (
            <Box sx={{ width: '50%' }}>
              <ImageFromServer sx={{ maxWidth: 300, maxHeight: 300 }} thumbnail={true} alt="" id={props.exercise.imageId} />
            </Box>
          )}
          <label htmlFor="icon-button-file">
            <IconButton color="primary" aria-label="upload picture" component="span">
              <Photo />
              &nbsp; Change Image
            </IconButton>
          </label>
          <FormControl component="fieldset" variant="standard">
            <FormGroup row>
              <FormControlLabel
                control={
                  <Switch
                    checked={props.exercise.active || false}
                    onChange={(e) => {
                      props.setExercise({ ...props.exercise!, active: !props.exercise?.active });
                    }}
                    name="active"
                  />
                }
                label="Active"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={props.exercise.multiLimb || false}
                    onChange={(e) => {
                      props.setExercise({ ...props.exercise!, multiLimb: !props.exercise?.multiLimb });
                    }}
                    name="alternate-sides"
                  />
                }
                label="Alternating sides"
              />
            </FormGroup>
          </FormControl>
          <ExerciseConfigComponent
            exerciseConfig={props.exercise.templateConfig}
            updateExerciseConfig={(config) => {
              props.setExercise({ ...props.exercise!, templateConfig: config });
            }}
          />
          <FormControlLabel
            control={
              <Switch
                checked={simpleTagConfiguration}
                onChange={(e) => {
                  setSimpleTagSelection(!simpleTagConfiguration);
                }}
                name="simpleTag"
              />
            }
            label="Simple Tag Selection"
          />
          {simpleTagConfiguration ? (
            <SimpleTagConfiguration exercise={props.exercise} setExercise={props.setExercise} />
          ) : (
            <TagConfiguration exercise={props.exercise} setExercise={props.setExercise} />
          )}
        </Stack>
        <ExternalLinksComponent exercise={props.exercise} setExercise={props.setExercise} />
        <Box>
          <EquipmentAutocomplete
            selectedEquipmentIds={props.exercise.equipmentIds}
            setSelectedEquipmentIds={(ids) => props.setExercise({ ...props.exercise, equipmentIds: ids })}
          />
        </Box>
        <Divider sx={{ m: 1 }} />
        <Box sx={{ m: 1 }}>
          <Button
            onClick={async () => {
              if (props.exercise !== undefined) {
                const errors = validateExercise(props.exercise);
                setValidationErrors(errors);
                if (errors.length > 0) return;
                let exercise = props.exercise;
                if (imageFile) {
                  const fileId = await uploadFile(imageFile);
                  exercise = { ...props.exercise, imageId: fileId };
                }
                await exerciseApi.exerciseUpdate({ exerciseDTO: exercise });
                props.exerciseUpdated(exercise);
              }

              props.setExercise(undefined);
            }}
          >
            Save
          </Button>
          <Button onClick={() => props.setExercise(undefined)}>Close</Button>
        </Box>
      </Box>
    </Modal>
  );
};
