import { ImageFromServer } from '@/components/images/ImageFromServer';
import { TagIntensityList } from '@/components/muscle-tags/TagIntensityList';
import { useContext, useState } from 'react';
import { AppContext } from '../../App';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Grid2,
  Grow,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  ListSubheader,
  Modal,
  Paper,
  Stack,
  Typography,
} from '@mui/material';
import { DeleteModal } from '../modals/DeleteSetModal';
import { enqueueSnackbar } from 'notistack';
import { ExerciseDTO } from '../../generated';
import { exerciseApi } from '../../generated/clients';
import { Alarm, AlarmOn, FitnessCenter, Pause, Repeat, Start } from '@mui/icons-material';
import Fieldset from '../Fieldset';

export enum DisplayMode {
  Details,
  Card,
}

interface ExerciseProps {
  display: DisplayMode;
  exercise: ExerciseDTO;
  timeout: number;
  onClick?: () => void;
  onExerciseDeleted?: () => void;
}

export const ExerciseComponent = (props: ExerciseProps) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  return (
    <>
      <DeleteModal
        show={showDeleteModal}
        setShow={setShowDeleteModal}
        nameOfObjectToDelete="Exercise"
        handleDelete={async () => {
          await exerciseApi.exerciseDelete({ exerciseId: props.exercise?.id ?? '' });
          enqueueSnackbar(`Exercise ${props.exercise.name} deleted !`, { variant: 'success' });
          setShowDeleteModal(false);
          if (props.onExerciseDeleted) props.onExerciseDeleted();
        }}
      ></DeleteModal>
      {props.display === DisplayMode.Card && <CardComponent {...props} setShowDeleteModal={setShowDeleteModal} />}
      {props.display === DisplayMode.Details && <DetailsComponent {...props} setShowDeleteModal={setShowDeleteModal} />}
    </>
  );
};

const CardComponent = (props: ExerciseProps & { setShowDeleteModal: React.Dispatch<React.SetStateAction<boolean>> }) => {
  const context = useContext(AppContext);
  const { exercise } = props;
  return (
    <Grid2 size={{ md: 4, xs: 12, sm: 6 }} sx={{ justifyItems: 'center' }} onClick={props.onClick}>
      <Card sx={{ minHeight: 150, maxWidth: 450, height: '100%' }}>
        <CardContent sx={{ height: '100%' }}>
          <Grid2 sx={{ height: '100%' }} container>
            <Grid2 size={5}>
              <ImageFromServer sx={{ flex: 1, maxWidth: 100, maxHeight: 100 }} thumbnail={true} alt="" id={exercise.imageId} />
            </Grid2>
            <Grid2 size={7}>
              <Typography variant="body1">{props.exercise.name}</Typography>
              <Typography variant="body2">{context.bodyZoneList.find((bz) => bz.id === props.exercise.mainBodyZoneId)?.name ?? 'N.A.'}</Typography>
              <Grid2 container>
                <Grid2 size={4}>
                  <Stack direction={'row'} gap={1} sx={{ m: 1 }}>
                    <FitnessCenter fontSize="small" />
                    <Typography variant="body2">{props.exercise.templateConfig.weight || '-'}</Typography>
                  </Stack>
                </Grid2>
                <Grid2 size={4}>
                  <Stack direction={'row'} gap={1} sx={{ m: 1 }}>
                    <Repeat fontSize="small" />
                    <Typography variant="body2">{props.exercise.templateConfig.repetitions || '-'}</Typography>{' '}
                  </Stack>
                </Grid2>
                <Grid2 size={4}></Grid2>
                <Grid2 size={4}>
                  <Stack direction={'row'} gap={1} sx={{ m: 1 }}>
                    <Alarm fontSize="small" />
                    <Typography variant="body2">{props.exercise.templateConfig.time || '-'}</Typography>
                  </Stack>
                </Grid2>
                <Grid2 size={4}>
                  <Stack direction={'row'} gap={1} sx={{ m: 1 }}>
                    <Start fontSize="small" /> <Typography variant="body2">{props.exercise.templateConfig.initTime || '-'}</Typography>
                  </Stack>
                </Grid2>
                <Grid2 size={4}>
                  <Stack direction={'row'} gap={1} sx={{ m: 1 }}>
                    <Pause fontSize="small" /> <Typography variant="body2">{props.exercise.templateConfig.breakTime || '-'}</Typography>
                  </Stack>
                </Grid2>
              </Grid2>
            </Grid2>
            <Grid2 size={12}>
              <Stack sx={{ width: '100%', height: '100%', justifyContent: 'flex-start', alignItems: 'flex-end' }}>
                {props.exercise.equipmentIds.length > 0 && (
                  <Box sx={{ width: '100%' }}>
                    <Fieldset title="Equipment">
                      <Typography>{props.exercise.equipmentIds.map((id, i) => `${i ? ',' : ''} ${context.equipmentList.find((e) => e.id === id)?.name}`)}</Typography>
                    </Fieldset>
                  </Box>
                )}
                <Button
                  sx={{ mt: 'auto' }}
                  onClick={(e) => {
                    e.stopPropagation();
                    props.setShowDeleteModal(true);
                  }}
                >
                  Delete
                </Button>
              </Stack>
            </Grid2>
          </Grid2>
        </CardContent>
      </Card>
    </Grid2>
  );
};

const EquipmentListItem = (props: { equipmentId: string }) => {
  const { equipmentId } = props;
  const context = useContext(AppContext);
  const equipment = context.equipmentList.find((e) => e.id === equipmentId);
  return (
    <ListItem>
      <ListItemAvatar>
        <Avatar>
          <ImageFromServer sx={{ maxWidth: 35 }} id={equipment?.imageId} thumbnail={true} alt="image" />
        </Avatar>
      </ListItemAvatar>
      <ListItemText>{`${equipment?.name}`}</ListItemText>
    </ListItem>
  );
};

const DetailsComponent = (props: ExerciseProps & { setShowDeleteModal: React.Dispatch<React.SetStateAction<boolean>> }) => {
  const context = useContext(AppContext);
  const [textModalMode, setTextModalMode] = useState<'desc' | 'link' | undefined>();

  return (
    <>
      <Modal open={textModalMode !== undefined} onClose={() => setTextModalMode(undefined)} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
          }}
        >
          {textModalMode === 'link' && <ExerciseLinks exercise={props.exercise} />}
          {textModalMode === 'desc' && <ExerciseDescription exercise={props.exercise} />}
        </Box>
      </Modal>
      <Grow timeout={props.timeout} in={true}>
        <Paper style={{ width: '100%' }} elevation={4}>
          <Grid2 container>
            <Grid2 size={12} sx={{ pl: 5, py: 3 }}>
              <Typography variant="h4">{props.exercise.name}</Typography>
            </Grid2>
            <Grid2 size={{ md: 3, xs: 12 }} sx={{ p: 1, justifyItems: 'center' }}>
              <Paper elevation={8} sx={{ p: 1 }}>
                <Box>
                  <ImageFromServer
                    sx={{
                      width: 240,
                      height: 240,
                    }}
                    thumbnail={true}
                    alt=""
                    id={props.exercise.imageId}
                  />
                </Box>
              </Paper>
            </Grid2>
            <Grid2 size={{ md: 5, sm: 12 }} sx={{ p: 1, justifyContent: 'center', width: '100%' }}>
              <Paper sx={{ pl: 1, pb: 1, maxHeight: 220, overflow: 'hidden', textOverflow: 'ellipsis' }} elevation={8} onClick={(e) => e.preventDefault()}>
                <Box onClick={() => setTextModalMode('desc')}>
                  <ExerciseDescription exercise={props.exercise} />
                </Box>
              </Paper>
              {props.exercise.exerciseLinks.length > 0 && (
                <Paper sx={{ pl: 2, pb: 1, mt: 1, maxHeight: 100, overflow: 'hidden', textOverflow: 'ellipsis' }} elevation={8}>
                  <Box onClick={() => setTextModalMode('link')}>
                    <ExerciseLinks exercise={props.exercise} />
                  </Box>
                </Paper>
              )}
            </Grid2>
            <Grid2 size={{ md: 4, xs: 12 }}>
              <Paper sx={{ pl: 1, pb: 1, m: 1 }} elevation={8}>
                <Typography variant="h6">Zone: {context.bodyZoneList.find((bz) => bz.id === props.exercise.mainBodyZoneId)?.name ?? 'Not selected'}</Typography>
                <Box sx={{ my: 1 }}>
                  <TagIntensityList muscleIntensities={props.exercise.muscleIntensities} />
                </Box>
              </Paper>
            </Grid2>
            <Grid2 size={{ md: 4, xs: 12 }} sx={{ p: 1, pl: 2 }}>
              <List sx={{ bgcolor: 'background.paper' }} component={'nav'} subheader={<ListSubheader sx={{ zIndex: 0 }}>Equipment</ListSubheader>}>
                {props.exercise.equipmentIds.map((id, i) => (
                  <EquipmentListItem equipmentId={id} />
                ))}
                {!props.exercise.equipmentIds.length && (
                  <ListItem>
                    <ListItemText>No Equipment</ListItemText>
                  </ListItem>
                )}
              </List>
            </Grid2>
            <Grid2 size={{ md: 3, sm: 12 }} sx={{ p: 1, width: '100%' }}>
              <List sx={{ bgcolor: 'background.paper', borderRadius: 1 }} component={'nav'}>
                <ListSubheader sx={{ zIndex: 0 }}>Info</ListSubheader>
                <ListItem>
                  <ListItemText>Weight: {props.exercise.templateConfig.weight || '-'}</ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText>Repetitions: {props.exercise.templateConfig.repetitions || '-'}</ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText>Alternate sides: {props.exercise.multiLimb ? 'Yes' : 'No'}</ListItemText>
                </ListItem>
              </List>
            </Grid2>
            <Grid2 size={{ md: 3, sm: 12 }} sx={{ p: 1, width: '100%' }}>
              <List sx={{ bgcolor: 'background.paper', borderRadius: 1 }} component={'nav'}>
                <ListSubheader sx={{ zIndex: 0 }}>Timer Info</ListSubheader>
                {props.exercise.templateConfig.useTimer && (
                  <>
                    <ListItem>
                      <ListItemText>Time: {props.exercise.templateConfig.time || '-'}</ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemText>Init Time: {props.exercise.templateConfig.initTime || '-'}</ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemText>Break Time: {props.exercise.templateConfig.breakTime || '-'}</ListItemText>
                    </ListItem>
                  </>
                )}
              </List>
            </Grid2>
            <Grid2 size={{ sm: 12, md: 2 }} sx={{ p: 1, zIndex: 0, width: '100%' }}>
              <List sx={{ backgroundColor: 'background.paper' }}>
                <ListSubheader>Actions</ListSubheader>
                <ListItem>
                  <ListItemButton onClick={props.onClick}>Edit</ListItemButton>
                </ListItem>
                <ListItem>
                  <ListItemButton
                    onClick={(e) => {
                      e.stopPropagation();
                      props.setShowDeleteModal(true);
                    }}
                  >
                    Delete
                  </ListItemButton>
                </ListItem>
              </List>
            </Grid2>
          </Grid2>
        </Paper>
      </Grow>
    </>
  );
};

const ExerciseDescription = (props: { exercise: ExerciseDTO }) => {
  return (
    <>
      <Typography variant="h6">Description:</Typography>
      <Typography>{props.exercise.description !== '' ? props.exercise.description : '-'}</Typography>
    </>
  );
};

const ExerciseLinks = (props: { exercise: ExerciseDTO }) => {
  return (
    <Stack>
      <Typography variant="h6">Links:</Typography>
      {props.exercise.exerciseLinks.map((el) => (
        <Box>
          <Link onClick={(e) => e.stopPropagation()} href={el.uri}>
            {el.title}
          </Link>
        </Box>
      ))}
    </Stack>
  );
};
