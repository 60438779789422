import { Autocomplete, TextField } from '@mui/material';
import { useContext } from 'react';
import { AppContext } from '../../App';

export default function EquipmentAutocomplete(props: { selectedEquipmentIds: string[]; setSelectedEquipmentIds: (ids: string[]) => void }) {
  const context = useContext(AppContext);
  const { selectedEquipmentIds, setSelectedEquipmentIds } = props;

  return (
    <Autocomplete
      multiple
      autoHighlight
      id="tags-outlined"
      defaultValue={context.equipmentList.filter((eq) => selectedEquipmentIds.includes(eq.id))}
      onChange={(event, equipment) => {
        setSelectedEquipmentIds(equipment.map((e) => e.id));
      }}
      options={context.equipmentList}
      getOptionLabel={(option) => option.name}
      filterSelectedOptions
      sx={{ mt: 1, minWidth: 200 }}
      renderInput={(params) => <TextField variant="outlined" {...params} label="Equipment" placeholder={selectedEquipmentIds.length === 0 ? 'Equipment' : undefined} />}
    />
  );
}
