import { Box, Button, Container, Grid2, Stack, TextField, Typography } from '@mui/material';
import { useEquipmentList } from '../hooks/use-equipment-list.hook';
import { CreateEquipmentModal } from '../components/modals/CreateEquipmentModal';
import { useContext, useState } from 'react';
import { equipmentApi } from '../generated/clients';
import { enqueueSnackbar } from 'notistack';
import { ImageFromServer } from '@/components/images/ImageFromServer';
import { EditEquipmentModal } from '../components/modals/EditEquipmentModal';
import { Equipment } from '../generated';
import { AppContext } from '../App';

export const EquipmentManager = () => {
  const [showCreateEquipmentModal, setShowCreateEquipmentModal] = useState(false);
  const context = useContext(AppContext);

  return (
    <Container>
      <CreateEquipmentModal
        showCreateEquipmentModal={showCreateEquipmentModal}
        setShowCreateEquipentModal={setShowCreateEquipmentModal}
        onEquipmentCreated={() => {
          context.refreshEquipmentList();
        }}
      />
      <Stack direction={'column'} sx={{ my: 1, width: '50%', mx: 'auto' }}>
        <Button
          onClick={async () => {
            setShowCreateEquipmentModal(true);
          }}
        >
          Create new Equipment
        </Button>
      </Stack>
      <Box sx={{ my: 1 }}>
        <Typography variant="h4">Equipment List:</Typography>
        <Grid2 sx={{ mt: 2 }} container>
          {context.equipmentList?.map((e) => (
            <EquipmentBox key={e.id} e={e} loadEquipmentList={() => context.refreshEquipmentList()} />
          ))}
        </Grid2>
      </Box>
    </Container>
  );
};

const EquipmentBox = (props: { e: Equipment; loadEquipmentList: () => Promise<void> }) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <EditEquipmentModal
        showEditEquipmentModal={showModal}
        equipment={props.e}
        setShowEditEquipentModal={setShowModal}
        onEquipmentUpdated={async () => {
          await props.loadEquipmentList();
        }}
      />
      <Grid2 container size={12} onClick={() => setShowModal(true)}>
        <Grid2 size={2}>
          <ImageFromServer sx={{ maxWidth: 100, maxHeight: 100 }} thumbnail={true} alt="" id={props.e.imageId} />
        </Grid2>
        <Grid2 size={2}>
          <Typography sx={{ m: 1 }} variant="h6">
            {props.e.name}
          </Typography>
        </Grid2>
        <Grid2 size={2}>
          <Typography sx={{ m: 1 }}>{props.e.description}</Typography>
        </Grid2>
        <Grid2 size={2}>
          <Button
            onClick={async () => {
              await equipmentApi.equipmentDelete({ equipmentId: props.e.id });
              enqueueSnackbar('Equipment deleted!');
              await props.loadEquipmentList();
            }}
            sx={{ m: 1 }}
          >
            Delete
          </Button>
        </Grid2>
      </Grid2>
    </>
  );
};
