/* tslint:disable */
/* eslint-disable */
/**
 * Sports App Training Plans
 * API for the Sports App Training Plans
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CreateEquipment,
  Equipment,
} from '../models/index';
import {
    CreateEquipmentFromJSON,
    CreateEquipmentToJSON,
    EquipmentFromJSON,
    EquipmentToJSON,
} from '../models/index';

export interface EquipmentCreateRequest {
    createEquipment: CreateEquipment;
}

export interface EquipmentDeleteRequest {
    equipmentId: string;
}

export interface EquipmentUpdateRequest {
    equipment: Equipment;
}

/**
 * 
 */
export class EquipmentApi extends runtime.BaseAPI {

    /**
     */
    async equipmentCreateRaw(requestParameters: EquipmentCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Equipment>> {
        if (requestParameters['createEquipment'] == null) {
            throw new runtime.RequiredError(
                'createEquipment',
                'Required parameter "createEquipment" was null or undefined when calling equipmentCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/equipment`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateEquipmentToJSON(requestParameters['createEquipment']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EquipmentFromJSON(jsonValue));
    }

    /**
     */
    async equipmentCreate(requestParameters: EquipmentCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Equipment> {
        const response = await this.equipmentCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async equipmentDeleteRaw(requestParameters: EquipmentDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['equipmentId'] == null) {
            throw new runtime.RequiredError(
                'equipmentId',
                'Required parameter "equipmentId" was null or undefined when calling equipmentDelete().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/equipment/{equipmentId}`.replace(`{${"equipmentId"}}`, encodeURIComponent(String(requestParameters['equipmentId']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async equipmentDelete(requestParameters: EquipmentDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.equipmentDeleteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async equipmentListRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Equipment>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/equipment`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(EquipmentFromJSON));
    }

    /**
     */
    async equipmentList(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Equipment>> {
        const response = await this.equipmentListRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async equipmentUpdateRaw(requestParameters: EquipmentUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Equipment>> {
        if (requestParameters['equipment'] == null) {
            throw new runtime.RequiredError(
                'equipment',
                'Required parameter "equipment" was null or undefined when calling equipmentUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/equipment`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: EquipmentToJSON(requestParameters['equipment']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EquipmentFromJSON(jsonValue));
    }

    /**
     */
    async equipmentUpdate(requestParameters: EquipmentUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Equipment> {
        const response = await this.equipmentUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
