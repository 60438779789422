import { MuscleIntensity } from '@/generated';
import { TagIntensity } from './TagIntensity';

interface TagIntensityList {
  muscleIntensities: Array<MuscleIntensity>;
  editable?: boolean;
  elementClicked?: (bzti: MuscleIntensity, deleted?: boolean) => void;
}

export const TagIntensityList = (props: TagIntensityList) => {
  {
    return (
      <>
        {props.muscleIntensities?.map((bzti) => (
          <TagIntensity muscleIntensity={bzti} editable={props.editable} elementClicked={props.elementClicked} />
        ))}
      </>
    );
  }
};
