import { useContext, useEffect, useState } from 'react';
import { AppContext } from '@/App';
import { Box, CircularProgress, SxProps } from '@mui/material';
import notFoundImage from '@/images/image-not-found.png';
import { fileApi } from '@/generated/clients';

export type ImageFromServerProps = {
  thumbnail: boolean;
  fluid?: boolean;
  alt: string;
  id?: string;
  sx?: SxProps;
};

export const ImageFromServer = (props: ImageFromServerProps) => {
  const context = useContext(AppContext);
  const [imageBase64, setImageBase64] = useState<string>();
  const [imageNotFound, setImageNotFound] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);

  let r = new FileReader();

  const load = async (id: string) => {
    try {
      setLoading(true);
      const response = (await fileApi.fileGetFileByIdRaw({ id })).raw;

      if (response.blob) r.readAsDataURL(await response.blob());
      else {
        setLoading(false);
        setImageNotFound(true);
      }
    } catch (e) {
      setImageNotFound(true);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!props.id) {
      setLoading(false);
      setImageNotFound(true);
      return;
    }
    if (!context?.fileCache?.has(props.id)) load(props.id);
    else {
      setImageBase64(context.fileCache.get(props.id));
      setLoading(false);
    }
  }, [props.id]);

  r.onloadend = (e) => {
    const result = r.result?.toString();
    if (result) {
      context?.fileCache?.set(props.id!, result);
      setImageBase64(result);
      setImageNotFound(false);
    }
    setLoading(false);
  };

  if (loading) return <CircularProgress />;

  if (imageBase64) return <Box {...props} sx={{ objectFit: 'contain', ...props.sx }} src={imageBase64} component={'img'} />;

  if (imageNotFound) return <Box {...props} style={{ objectFit: 'cover', width: '100%', height: '100%' }} src={notFoundImage} component={'img'} />;

  return <></>;
};
