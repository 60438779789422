/* tslint:disable */
/* eslint-disable */
/**
 * Sports App Training Plans
 * API for the Sports App Training Plans
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CopyExerciseDTO,
  CreateExerciseDTO,
  ExerciseDTO,
} from '../models/index';
import {
    CopyExerciseDTOFromJSON,
    CopyExerciseDTOToJSON,
    CreateExerciseDTOFromJSON,
    CreateExerciseDTOToJSON,
    ExerciseDTOFromJSON,
    ExerciseDTOToJSON,
} from '../models/index';

export interface ExerciseCopyRequest {
    copyExerciseDTO: CopyExerciseDTO;
}

export interface ExerciseCreateRequest {
    createExerciseDTO: CreateExerciseDTO;
}

export interface ExerciseDeleteRequest {
    exerciseId: string;
}

export interface ExerciseListRequest {
    setId: string;
}

export interface ExerciseUpdateRequest {
    exerciseDTO: ExerciseDTO;
}

/**
 * 
 */
export class ExerciseApi extends runtime.BaseAPI {

    /**
     */
    async exerciseCopyRaw(requestParameters: ExerciseCopyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ExerciseDTO>> {
        if (requestParameters['copyExerciseDTO'] == null) {
            throw new runtime.RequiredError(
                'copyExerciseDTO',
                'Required parameter "copyExerciseDTO" was null or undefined when calling exerciseCopy().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/exercise/copy`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CopyExerciseDTOToJSON(requestParameters['copyExerciseDTO']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ExerciseDTOFromJSON(jsonValue));
    }

    /**
     */
    async exerciseCopy(requestParameters: ExerciseCopyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ExerciseDTO> {
        const response = await this.exerciseCopyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async exerciseCreateRaw(requestParameters: ExerciseCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ExerciseDTO>> {
        if (requestParameters['createExerciseDTO'] == null) {
            throw new runtime.RequiredError(
                'createExerciseDTO',
                'Required parameter "createExerciseDTO" was null or undefined when calling exerciseCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/exercise`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateExerciseDTOToJSON(requestParameters['createExerciseDTO']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ExerciseDTOFromJSON(jsonValue));
    }

    /**
     */
    async exerciseCreate(requestParameters: ExerciseCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ExerciseDTO> {
        const response = await this.exerciseCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async exerciseDeleteRaw(requestParameters: ExerciseDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['exerciseId'] == null) {
            throw new runtime.RequiredError(
                'exerciseId',
                'Required parameter "exerciseId" was null or undefined when calling exerciseDelete().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/exercise/{exerciseId}`.replace(`{${"exerciseId"}}`, encodeURIComponent(String(requestParameters['exerciseId']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async exerciseDelete(requestParameters: ExerciseDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.exerciseDeleteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async exerciseListRaw(requestParameters: ExerciseListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ExerciseDTO>>> {
        if (requestParameters['setId'] == null) {
            throw new runtime.RequiredError(
                'setId',
                'Required parameter "setId" was null or undefined when calling exerciseList().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/exercise/{setId}`.replace(`{${"setId"}}`, encodeURIComponent(String(requestParameters['setId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ExerciseDTOFromJSON));
    }

    /**
     */
    async exerciseList(requestParameters: ExerciseListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ExerciseDTO>> {
        const response = await this.exerciseListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async exerciseUpdateRaw(requestParameters: ExerciseUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ExerciseDTO>> {
        if (requestParameters['exerciseDTO'] == null) {
            throw new runtime.RequiredError(
                'exerciseDTO',
                'Required parameter "exerciseDTO" was null or undefined when calling exerciseUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/exercise`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ExerciseDTOToJSON(requestParameters['exerciseDTO']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ExerciseDTOFromJSON(jsonValue));
    }

    /**
     */
    async exerciseUpdate(requestParameters: ExerciseUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ExerciseDTO> {
        const response = await this.exerciseUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
