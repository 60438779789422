export interface LoginUser {
  username: string;
  password: string;
}

export interface LocalUser extends LoginUser {
  access_token?: string;
  accountType: AccountType;
  id: string;
  identityProviderToken?: string;
  roles: Array<Role>;
  lastLogin: Date;
}

export enum Role {
  User = 'user',
  Admin = 'admin',
}

export enum AccountType {
  'basic',
  'premium',
}
