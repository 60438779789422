import { useState, useEffect } from 'react';
import { Equipment } from '../generated';
import { equipmentApi } from '../generated/clients';

export const useEquipmentList = (): [Equipment[], () => Promise<void>] => {
  const [equipment, setEquipment] = useState<Equipment[]>([]);
  const loadEquipment = async () => {
    const loadedEquipment = await equipmentApi.equipmentList();
    setEquipment(loadedEquipment);
  };
  useEffect(() => {
    loadEquipment();
  }, []);
  return [equipment, loadEquipment];
};
