import { useRef, useState } from 'react';
import { Box, Button, Checkbox, FormControlLabel, IconButton, Modal, Stack, TextField, Typography } from '@mui/material';
import { modalStyle } from './modalStyle';
import { ImageFromFile } from '@/components/images/ImageFromFile';
import { Photo } from '@mui/icons-material';
import ReactCrop, { Crop, PixelCrop } from 'react-image-crop';
import { imgPreview } from '../../utils/imageRendering';
import { ImageFromServer } from '@/components/images/ImageFromServer';
import { enqueueSnackbar } from 'notistack';
import { equipmentApi } from '../../generated/clients';
import { uploadFile } from '../../utils/api-helpers';
import { Equipment } from '../../generated';
import { isAdminUser } from '../../utils/user';

export interface EditEquimpmentModalProps {
  showEditEquipmentModal: boolean;
  equipment: Equipment;
  onEquipmentUpdated: (e: Equipment) => void;
  setShowEditEquipentModal: (s: boolean) => void;
}

interface ImageCropComponentProps {
  imageId?: string;
  onImageFileChanged?: (file: File | null | undefined) => void;
  imageFile: File | null | undefined;
}

const ImageCropComponent = ({ imageId, onImageFileChanged, imageFile }: ImageCropComponentProps) => {
  const imgRef = useRef<HTMLImageElement>(null);
  const [crop, setCrop] = useState<Crop>();
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();

  return (
    <>
      {imageFile ? (
        <>
          <ReactCrop crop={crop} onComplete={(c) => setCompletedCrop(c)} onChange={(c) => setCrop(c)}>
            <ImageFromFile ref={imgRef} thumbnail={true} style={{ maxWidth: 300, maxHeight: 300 }} image={imageFile}></ImageFromFile>
          </ReactCrop>
          <Button
            onClick={async () => {
              if (completedCrop && imgRef.current) {
                const blob = await imgPreview(imgRef.current, completedCrop);
                const imageFile = new File([blob], 'image.jpeg', {
                  type: blob.type,
                });
                if (onImageFileChanged) onImageFileChanged(imageFile);
              }
            }}
          >
            Crop
          </Button>
        </>
      ) : (
        <Box sx={{ width: '50%' }}>
          <ImageFromServer sx={{ maxWidth: 300, maxHeight: 300 }} thumbnail={true} alt="" id={imageId} />
        </Box>
      )}
      <input
        style={{ display: 'none' }}
        onChange={(e) => {
          if (onImageFileChanged) onImageFileChanged((e.target as any).files ? (e.target as any).files[0] : null);
        }}
        accept="image/*"
        id="icon-button-file"
        type="file"
      />
      <label htmlFor="icon-button-file">
        <IconButton color="primary" aria-label="upload picture" component="span">
          <Photo />
          &nbsp; Change Image
        </IconButton>
      </label>
    </>
  );
};

export const EditEquipmentModal = (props: EditEquimpmentModalProps) => {
  const { showEditEquipmentModal: showCreateEquipmentModal, setShowEditEquipentModal: setShowCreateSetModal } = props;
  const [equipment, setEquipment] = useState<Equipment>(props.equipment);
  const [imageFile, setImageFile] = useState<File | null | undefined>();

  return (
    <Modal open={showCreateEquipmentModal} onClose={() => setShowCreateSetModal(false)}>
      <Box sx={modalStyle}>
        <Typography variant="h4">Edit Equipment</Typography>
        <Stack direction={'row'} sx={{ gap: 2, justifyItems: 'center' }}>
          <TextField value={equipment.name} style={{ marginTop: 30 }} onChange={(e) => setEquipment({ ...equipment, name: e.target.value })} label="Name" />
          <FormControlLabel
            control={
              <Checkbox
                checked={equipment.isPublic}
                onChange={() => {
                  setEquipment({ ...equipment, isPublic: !equipment.isPublic });
                }}
              />
            }
            label="Public"
            disabled={!isAdminUser()}
          />
        </Stack>

        <Box>
          <TextField
            value={equipment.description}
            label={'Equipment Description'}
            onChange={(v) => setEquipment({ ...equipment, description: v.target.value })}
            style={{ width: 250, marginTop: 10 }}
            multiline
            rows={4}
          />
        </Box>
        <Box sx={{ m: 1 }}>
          <ImageCropComponent imageFile={imageFile} onImageFileChanged={setImageFile} imageId={props.equipment.imageId} />
        </Box>
        <Button
          style={{ marginTop: 10 }}
          onClick={async () => {
            setShowCreateSetModal(false);
            try {
              let fileId = equipment.imageId;
              if (imageFile) {
                fileId = await uploadFile(imageFile);
              }

              const e = await equipmentApi.equipmentUpdate({ equipment: { ...equipment, imageId: fileId } });

              enqueueSnackbar('Equipment updated!');
              props.onEquipmentUpdated(e);
            } catch (e) {
              if (e instanceof Error) {
                enqueueSnackbar(JSON.parse(e.message).message, { variant: 'error' });
              }
            }
          }}
        >
          Save{' '}
        </Button>
        <Button style={{ marginTop: 10 }} onClick={() => setShowCreateSetModal(false)}>
          Close
        </Button>
      </Box>
    </Modal>
  );
};
