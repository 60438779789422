/* tslint:disable */
/* eslint-disable */
/**
 * Sports App Training Plans
 * API for the Sports App Training Plans
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { MuscleIntensity } from './MuscleIntensity';
import {
    MuscleIntensityFromJSON,
    MuscleIntensityFromJSONTyped,
    MuscleIntensityToJSON,
    MuscleIntensityToJSONTyped,
} from './MuscleIntensity';
import type { ExerciseLink } from './ExerciseLink';
import {
    ExerciseLinkFromJSON,
    ExerciseLinkFromJSONTyped,
    ExerciseLinkToJSON,
    ExerciseLinkToJSONTyped,
} from './ExerciseLink';
import type { TemplateConfig } from './TemplateConfig';
import {
    TemplateConfigFromJSON,
    TemplateConfigFromJSONTyped,
    TemplateConfigToJSON,
    TemplateConfigToJSONTyped,
} from './TemplateConfig';

/**
 * 
 * @export
 * @interface CreateExerciseDTO
 */
export interface CreateExerciseDTO {
    /**
     * 
     * @type {boolean}
     * @memberof CreateExerciseDTO
     */
    active: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateExerciseDTO
     */
    setId: string;
    /**
     * 
     * @type {string}
     * @memberof CreateExerciseDTO
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CreateExerciseDTO
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof CreateExerciseDTO
     */
    imageId?: string;
    /**
     * 
     * @type {TemplateConfig}
     * @memberof CreateExerciseDTO
     */
    templateConfig: TemplateConfig;
    /**
     * 
     * @type {string}
     * @memberof CreateExerciseDTO
     */
    videoId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateExerciseDTO
     */
    multiLimb: boolean;
    /**
     * 
     * @type {Array<MuscleIntensity>}
     * @memberof CreateExerciseDTO
     */
    muscleIntensities: Array<MuscleIntensity>;
    /**
     * 
     * @type {Array<ExerciseLink>}
     * @memberof CreateExerciseDTO
     */
    exerciseLinks: Array<ExerciseLink>;
    /**
     * 
     * @type {string}
     * @memberof CreateExerciseDTO
     */
    mainBodyZoneId: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateExerciseDTO
     */
    equipmentIds: Array<string>;
}

/**
 * Check if a given object implements the CreateExerciseDTO interface.
 */
export function instanceOfCreateExerciseDTO(value: object): value is CreateExerciseDTO {
    if (!('active' in value) || value['active'] === undefined) return false;
    if (!('setId' in value) || value['setId'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('description' in value) || value['description'] === undefined) return false;
    if (!('templateConfig' in value) || value['templateConfig'] === undefined) return false;
    if (!('multiLimb' in value) || value['multiLimb'] === undefined) return false;
    if (!('muscleIntensities' in value) || value['muscleIntensities'] === undefined) return false;
    if (!('exerciseLinks' in value) || value['exerciseLinks'] === undefined) return false;
    if (!('mainBodyZoneId' in value) || value['mainBodyZoneId'] === undefined) return false;
    if (!('equipmentIds' in value) || value['equipmentIds'] === undefined) return false;
    return true;
}

export function CreateExerciseDTOFromJSON(json: any): CreateExerciseDTO {
    return CreateExerciseDTOFromJSONTyped(json, false);
}

export function CreateExerciseDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateExerciseDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'active': json['active'],
        'setId': json['setId'],
        'name': json['name'],
        'description': json['description'],
        'imageId': json['imageId'] == null ? undefined : json['imageId'],
        'templateConfig': TemplateConfigFromJSON(json['templateConfig']),
        'videoId': json['videoId'] == null ? undefined : json['videoId'],
        'multiLimb': json['multiLimb'],
        'muscleIntensities': ((json['muscleIntensities'] as Array<any>).map(MuscleIntensityFromJSON)),
        'exerciseLinks': ((json['exerciseLinks'] as Array<any>).map(ExerciseLinkFromJSON)),
        'mainBodyZoneId': json['mainBodyZoneId'],
        'equipmentIds': json['equipmentIds'],
    };
}

export function CreateExerciseDTOToJSON(json: any): CreateExerciseDTO {
    return CreateExerciseDTOToJSONTyped(json, false);
}

export function CreateExerciseDTOToJSONTyped(value?: CreateExerciseDTO | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'active': value['active'],
        'setId': value['setId'],
        'name': value['name'],
        'description': value['description'],
        'imageId': value['imageId'],
        'templateConfig': TemplateConfigToJSON(value['templateConfig']),
        'videoId': value['videoId'],
        'multiLimb': value['multiLimb'],
        'muscleIntensities': ((value['muscleIntensities'] as Array<any>).map(MuscleIntensityToJSON)),
        'exerciseLinks': ((value['exerciseLinks'] as Array<any>).map(ExerciseLinkToJSON)),
        'mainBodyZoneId': value['mainBodyZoneId'],
        'equipmentIds': value['equipmentIds'],
    };
}

