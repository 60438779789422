import { fetchWithReauth } from '../utils/auth';
import { BodyZoneApi, EquipmentApi, ExerciseApi, ExportApi, FileApi, MuscleTagApi, OCRApi, SetApi, ThemeApi, UserApi, WorkoutApi } from './apis';
import { Configuration } from './runtime';

export const apiBasePath = `${process.env.REACT_APP_PUBLIC_URL}${process.env.REACT_APP_API_PATH}`;

const loggedInConfiguration = new Configuration({
  fetchApi: fetchWithReauth,
  basePath: apiBasePath,
});

export const bodyZoneApi = new BodyZoneApi(loggedInConfiguration);
export const exerciseApi = new ExerciseApi(loggedInConfiguration);
export const exportApi = new ExportApi(loggedInConfiguration);
export const fileApi = new FileApi(loggedInConfiguration);
export const muscleTagApi = new MuscleTagApi(loggedInConfiguration);
export const ocrApi = new OCRApi(loggedInConfiguration);
export const setApi = new SetApi(loggedInConfiguration);
export const themeApi = new ThemeApi(loggedInConfiguration);
export const userApi = new UserApi(loggedInConfiguration);
export const workoutApi = new WorkoutApi(loggedInConfiguration);
export const equipmentApi = new EquipmentApi(loggedInConfiguration);
