/* tslint:disable */
/* eslint-disable */
/**
 * Sports App Training Plans
 * API for the Sports App Training Plans
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RegisterUserBaseDTO
 */
export interface RegisterUserBaseDTO {
    /**
     * 
     * @type {string}
     * @memberof RegisterUserBaseDTO
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterUserBaseDTO
     */
    username: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterUserBaseDTO
     */
    password: string;
}

/**
 * Check if a given object implements the RegisterUserBaseDTO interface.
 */
export function instanceOfRegisterUserBaseDTO(value: object): value is RegisterUserBaseDTO {
    if (!('email' in value) || value['email'] === undefined) return false;
    if (!('username' in value) || value['username'] === undefined) return false;
    if (!('password' in value) || value['password'] === undefined) return false;
    return true;
}

export function RegisterUserBaseDTOFromJSON(json: any): RegisterUserBaseDTO {
    return RegisterUserBaseDTOFromJSONTyped(json, false);
}

export function RegisterUserBaseDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): RegisterUserBaseDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'email': json['email'],
        'username': json['username'],
        'password': json['password'],
    };
}

export function RegisterUserBaseDTOToJSON(json: any): RegisterUserBaseDTO {
    return RegisterUserBaseDTOToJSONTyped(json, false);
}

export function RegisterUserBaseDTOToJSONTyped(value?: RegisterUserBaseDTO | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'email': value['email'],
        'username': value['username'],
        'password': value['password'],
    };
}

