import { useEffect, useState } from 'react';
import { useSetList } from '../../hooks/set-list.hook';
import { Backdrop, Box, Button, CircularProgress, MenuItem, Modal, Select, TextField, Typography } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { setApi } from '../../generated/clients';
import { SetDTO } from '../../generated';
import { modalStyle } from './modalStyle';

export interface CreateSetFromOtherModalProps {
  show: boolean;
  setShow: (s: boolean) => void;
  setRefreshSets: (s: boolean) => void;
}

export const CreateSetFromOtherModal = (props: CreateSetFromOtherModalProps) => {
  const [set, setSet] = useState<SetDTO>();
  const [setDescription, setSetDescription] = useState<string>();
  const setList = useSetList();
  const [showBackdrop, setShowBackdrop] = useState(false);
  const [setName, setSetName] = useState<string>();

  useEffect(() => {
    if (setList && setList.length > 0) setSet(setList[0]);
  }, [setList]);

  return (
    <>
      <Backdrop sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })} open={showBackdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Modal open={props.show} onClose={() => props.setShow(false)}>
        <Box sx={modalStyle}>
          <Typography variant="h4">Create Set from other</Typography>
          <Box sx={{ marginTop: 2 }}>
            <Select value={set?.name}>
              {setList?.map((s) => (
                <MenuItem value={s.name} onClick={() => setSet(s)}>
                  {s.name}
                </MenuItem>
              ))}
            </Select>
          </Box>
          <TextField style={{ marginTop: 10 }} onChange={(e) => setSetName(e.target.value)} label="Name" />
          <Box>
            <TextField label={'Set Description'} onChange={(v) => setSetDescription(v.target.value)} style={{ width: 250, marginTop: 10 }} multiline rows={4} />
          </Box>
          <Button
            onClick={async () => {
              props.setShow(false);

              if (set) {
                try {
                  setShowBackdrop(true);
                  await setApi.setCopySet({ copySetDTO: { sourceSetId: set.id || '', name: setName || set.name + '_copy', description: setDescription } });
                  props.setRefreshSets(true);
                } catch (e) {
                  if (e instanceof Error) {
                    enqueueSnackbar(JSON.parse(e.message).message, { variant: 'error' });
                  }
                } finally {
                  setShowBackdrop(false);
                }
              }
            }}
          >
            Create Set
          </Button>
          <Button onClick={() => props.setShow(false)}>Close</Button>
        </Box>
      </Modal>
    </>
  );
};
