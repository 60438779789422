import { useState } from 'react';
import { AccountType, Role } from '../../models/user';
import { Box, Button, Checkbox, FormControl, FormControlLabel, FormLabel, Modal, Radio, RadioGroup, Stack, TextField, Typography } from '@mui/material';
import { modalStyle } from './modalStyle';
import { userApi } from '../../generated/clients';
import { CreateUserDTO } from '../../generated';

export interface CreateUserModalProps {
  showCreateUserModal: boolean;
  setShowCreateUserModal: (s: boolean) => void;
}

export const CreateUserModal = (props: CreateUserModalProps) => {
  const [userName, setUserName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [accountType, setAccountType] = useState<AccountType>(AccountType.basic);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [isUser, setIsUser] = useState<boolean>(true);
  const [isActive, setIsActive] = useState<boolean>(true);

  return (
    <Modal open={props.showCreateUserModal} onClose={() => props.setShowCreateUserModal(false)}>
      <Box sx={modalStyle}>
        <Typography sx={{ margin: 2 }} variant="h5">
          Create New User
        </Typography>
        <TextField onChange={(e) => setUserName(e.target.value)} label="Name" />
        &nbsp;
        <TextField onChange={(e) => setEmail(e.target.value)} label="Email" />
        &nbsp;
        <TextField type="password" onChange={(e) => setPassword(e.target.value)} />
        <div key={`roles`} className="mb-3">
          <FormControlLabel control={<Checkbox checked={isAdmin} onChange={(e) => setIsAdmin(e.target.checked)} />} label="Admin" />
          <FormControlLabel control={<Checkbox checked={isUser} onChange={(e) => setIsUser(e.target.checked)} />} label="User" />
        </div>
        <FormControlLabel control={<Checkbox checked={isActive} onChange={(e) => setIsActive(e.target.checked)} />} label="Active" />
        <Box>
          <FormControl>
            <FormLabel id="demo-radio-buttons-group-label">Account Type</FormLabel>
            <RadioGroup
              row
              onChange={(e) => {
                setAccountType(e.target.value as unknown as AccountType);
              }}
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue={AccountType.basic}
              name="radio-buttons-group"
            >
              <FormControlLabel value={AccountType.basic} control={<Radio />} label="Basic" />
              <FormControlLabel value={AccountType.premium} control={<Radio />} label="Premium" />
            </RadioGroup>
          </FormControl>
        </Box>
        <Box>
          <Button
            onClick={async () => {
              const roles: Array<Role> = [];
              if (isAdmin) roles.push(Role.Admin);
              if (isUser) roles.push(Role.User);

              const newUser: CreateUserDTO = { active: isActive, roles, password, username: userName, email: email, accountType, identityProvider: 'local' };
              const result = await userApi.userCreateUser({ createUserDTO: newUser });
              props.setShowCreateUserModal(false);
            }}
          >
            Create User
          </Button>
          <Button onClick={() => props.setShowCreateUserModal(false)}>Close</Button>
        </Box>
      </Box>
    </Modal>
  );
};
