export enum SoundType {
  skip,
  new_plan,
  download,
  short_beep,
  long_beep,
  Init,
  swap,
  drop,
  cheer,
}
