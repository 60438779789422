/* tslint:disable */
/* eslint-disable */
/**
 * Sports App Training Plans
 * API for the Sports App Training Plans
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AppDataDTO,
  CreateUserDTO,
  RegisterUserBaseDTO,
  RegisterUserWebAppDTO,
  UpdateUserDTO,
  UserDTO,
  UserProfileDTO,
} from '../models/index';
import {
    AppDataDTOFromJSON,
    AppDataDTOToJSON,
    CreateUserDTOFromJSON,
    CreateUserDTOToJSON,
    RegisterUserBaseDTOFromJSON,
    RegisterUserBaseDTOToJSON,
    RegisterUserWebAppDTOFromJSON,
    RegisterUserWebAppDTOToJSON,
    UpdateUserDTOFromJSON,
    UpdateUserDTOToJSON,
    UserDTOFromJSON,
    UserDTOToJSON,
    UserProfileDTOFromJSON,
    UserProfileDTOToJSON,
} from '../models/index';

export interface UserCreateUserRequest {
    createUserDTO: CreateUserDTO;
}

export interface UserDeleteUserRequest {
    id: string;
}

export interface UserForgotPasswordRequest {
    email: string;
}

export interface UserGetAppDataRequest {
    id: string;
}

export interface UserGetUserByIdRequest {
    id: string;
}

export interface UserRegisterRequest {
    registerUserWebAppDTO: RegisterUserWebAppDTO;
}

export interface UserRegisterAppRequest {
    registerUserBaseDTO: RegisterUserBaseDTO;
}

export interface UserUpdateAppDataRequest {
    id: string;
    appDataDTO: AppDataDTO;
}

export interface UserUpdateUserRequest {
    updateUserDTO: UpdateUserDTO;
}

export interface UserVerifyRequest {
    token: string;
}

/**
 * 
 */
export class UserApi extends runtime.BaseAPI {

    /**
     */
    async userCreateUserRaw(requestParameters: UserCreateUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserDTO>> {
        if (requestParameters['createUserDTO'] == null) {
            throw new runtime.RequiredError(
                'createUserDTO',
                'Required parameter "createUserDTO" was null or undefined when calling userCreateUser().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/user`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUserDTOToJSON(requestParameters['createUserDTO']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserDTOFromJSON(jsonValue));
    }

    /**
     */
    async userCreateUser(requestParameters: UserCreateUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserDTO> {
        const response = await this.userCreateUserRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async userDeleteUserRaw(requestParameters: UserDeleteUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling userDeleteUser().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/user/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async userDeleteUser(requestParameters: UserDeleteUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.userDeleteUserRaw(requestParameters, initOverrides);
    }

    /**
     */
    async userDeleteUserAccountRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/user/delete-account`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async userDeleteUserAccount(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.userDeleteUserAccountRaw(initOverrides);
    }

    /**
     */
    async userForgotPasswordRaw(requestParameters: UserForgotPasswordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['email'] == null) {
            throw new runtime.RequiredError(
                'email',
                'Required parameter "email" was null or undefined when calling userForgotPassword().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/user/forgot-password/{email}`.replace(`{${"email"}}`, encodeURIComponent(String(requestParameters['email']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async userForgotPassword(requestParameters: UserForgotPasswordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.userForgotPasswordRaw(requestParameters, initOverrides);
    }

    /**
     */
    async userGetAppDataRaw(requestParameters: UserGetAppDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling userGetAppData().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/user/app-data/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async userGetAppData(requestParameters: UserGetAppDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.userGetAppDataRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async userGetProfileRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserProfileDTO>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/user/profile`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserProfileDTOFromJSON(jsonValue));
    }

    /**
     */
    async userGetProfile(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserProfileDTO> {
        const response = await this.userGetProfileRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async userGetUserByIdRaw(requestParameters: UserGetUserByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserDTO>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling userGetUserById().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/user/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserDTOFromJSON(jsonValue));
    }

    /**
     */
    async userGetUserById(requestParameters: UserGetUserByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserDTO> {
        const response = await this.userGetUserByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async userListUsersRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<UserDTO>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/user`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserDTOFromJSON));
    }

    /**
     */
    async userListUsers(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<UserDTO>> {
        const response = await this.userListUsersRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async userLoginRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/user/auth/login`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async userLogin(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.userLoginRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async userMeRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/user/auth/me`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async userMe(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.userMeRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async userRegisterRaw(requestParameters: UserRegisterRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserProfileDTO>> {
        if (requestParameters['registerUserWebAppDTO'] == null) {
            throw new runtime.RequiredError(
                'registerUserWebAppDTO',
                'Required parameter "registerUserWebAppDTO" was null or undefined when calling userRegister().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/user/register`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RegisterUserWebAppDTOToJSON(requestParameters['registerUserWebAppDTO']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserProfileDTOFromJSON(jsonValue));
    }

    /**
     */
    async userRegister(requestParameters: UserRegisterRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserProfileDTO> {
        const response = await this.userRegisterRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async userRegisterAppRaw(requestParameters: UserRegisterAppRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserProfileDTO>> {
        if (requestParameters['registerUserBaseDTO'] == null) {
            throw new runtime.RequiredError(
                'registerUserBaseDTO',
                'Required parameter "registerUserBaseDTO" was null or undefined when calling userRegisterApp().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/user/register-app`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RegisterUserBaseDTOToJSON(requestParameters['registerUserBaseDTO']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserProfileDTOFromJSON(jsonValue));
    }

    /**
     */
    async userRegisterApp(requestParameters: UserRegisterAppRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserProfileDTO> {
        const response = await this.userRegisterAppRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async userUpdateAppDataRaw(requestParameters: UserUpdateAppDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling userUpdateAppData().'
            );
        }

        if (requestParameters['appDataDTO'] == null) {
            throw new runtime.RequiredError(
                'appDataDTO',
                'Required parameter "appDataDTO" was null or undefined when calling userUpdateAppData().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/user/app-data/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AppDataDTOToJSON(requestParameters['appDataDTO']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async userUpdateAppData(requestParameters: UserUpdateAppDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.userUpdateAppDataRaw(requestParameters, initOverrides);
    }

    /**
     */
    async userUpdateUserRaw(requestParameters: UserUpdateUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['updateUserDTO'] == null) {
            throw new runtime.RequiredError(
                'updateUserDTO',
                'Required parameter "updateUserDTO" was null or undefined when calling userUpdateUser().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/user`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateUserDTOToJSON(requestParameters['updateUserDTO']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async userUpdateUser(requestParameters: UserUpdateUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.userUpdateUserRaw(requestParameters, initOverrides);
    }

    /**
     */
    async userVerifyRaw(requestParameters: UserVerifyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['token'] == null) {
            throw new runtime.RequiredError(
                'token',
                'Required parameter "token" was null or undefined when calling userVerify().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/user/verify/{token}`.replace(`{${"token"}}`, encodeURIComponent(String(requestParameters['token']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async userVerify(requestParameters: UserVerifyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.userVerifyRaw(requestParameters, initOverrides);
    }

}
