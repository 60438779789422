/* tslint:disable */
/* eslint-disable */
/**
 * Sports App Training Plans
 * API for the Sports App Training Plans
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateUserDTO
 */
export interface UpdateUserDTO {
    /**
     * 
     * @type {string}
     * @memberof UpdateUserDTO
     */
    userId: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserDTO
     */
    email: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateUserDTO
     */
    accountType: UpdateUserDTOAccountTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserDTO
     */
    username: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateUserDTO
     */
    active: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateUserDTO
     */
    roles: Array<UpdateUserDTORolesEnum>;
}


/**
 * @export
 */
export const UpdateUserDTOAccountTypeEnum = {
    NUMBER_0: 0,
    NUMBER_1: 1
} as const;
export type UpdateUserDTOAccountTypeEnum = typeof UpdateUserDTOAccountTypeEnum[keyof typeof UpdateUserDTOAccountTypeEnum];

/**
 * @export
 */
export const UpdateUserDTORolesEnum = {
    User: 'user',
    Admin: 'admin'
} as const;
export type UpdateUserDTORolesEnum = typeof UpdateUserDTORolesEnum[keyof typeof UpdateUserDTORolesEnum];


/**
 * Check if a given object implements the UpdateUserDTO interface.
 */
export function instanceOfUpdateUserDTO(value: object): value is UpdateUserDTO {
    if (!('userId' in value) || value['userId'] === undefined) return false;
    if (!('email' in value) || value['email'] === undefined) return false;
    if (!('accountType' in value) || value['accountType'] === undefined) return false;
    if (!('username' in value) || value['username'] === undefined) return false;
    if (!('active' in value) || value['active'] === undefined) return false;
    if (!('roles' in value) || value['roles'] === undefined) return false;
    return true;
}

export function UpdateUserDTOFromJSON(json: any): UpdateUserDTO {
    return UpdateUserDTOFromJSONTyped(json, false);
}

export function UpdateUserDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateUserDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'userId': json['userId'],
        'email': json['email'],
        'accountType': json['accountType'],
        'username': json['username'],
        'active': json['active'],
        'roles': json['roles'],
    };
}

export function UpdateUserDTOToJSON(json: any): UpdateUserDTO {
    return UpdateUserDTOToJSONTyped(json, false);
}

export function UpdateUserDTOToJSONTyped(value?: UpdateUserDTO | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'userId': value['userId'],
        'email': value['email'],
        'accountType': value['accountType'],
        'username': value['username'],
        'active': value['active'],
        'roles': value['roles'],
    };
}

